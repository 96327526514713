<!--  -->
<template>
  <div class="filePage" :class="[isMobileMain ? 'isMobileMain' : '']">
    <div class="protocol-head" v-if="!isMobileMain">
      <div class="protocol-head-container flex-vc flex">
        <a href="/" class="flex-vc flex">
          <div class="logo">
            <img src="../../assets/img/default/upupoo.png" />
          </div>
          <span class="text">upupoo</span>
        </a>
      </div>
    </div>
    <div class="protocol-layout-body">
      <div class="protocol-content">
        <h3>《喵币用户协议》</h3>
        <p>您使用喵币服务前应当阅读并理解本协议，<span class="protocal-title-bold">本协议中与您的权益（可能）存在重大关系，UPUPOO已采用字体加粗的方式来特别提醒您，请您留意重点查阅。</span></p>
        <p class="protocal-title-bold">若您不同意本协议中所述任何条款或其后对协议条款的修改，请立即停止使用喵币服务，您使用喵币服务的行为（包括但不限于点击购买、支付等）将被视为已经仔细阅读、充分理解并毫无保留地接受本协议所有条款。</p>
        <p class="protocal-title-bold">特别提示：如您尚未成年，请在法定监护人的陪同下阅读和判断是否同意本协议。未成年人行使和履行本协议项下的权利和义务视为已获得法定监护人的认可。</p>

        <p class="protocal-title-bold">一、喵币</p>
        <p>1. 喵币是UPUPOO为您提供的数字化商品，用于购买UPUPOO上的各种虚拟产品。</p>
        <p class="protocal-title-bold">2. 您使用喵币在UPUPOO上购买虚拟产品（包括但不限于视频壁纸、图片壁纸、鼠标、任务栏和开始菜单等）后，将获得其在UPUPOO客户端内下载使用的权利，源文件和版权仍归原作者所有，未避免源文件二次传播，UPUPOO不提供源文件下载。</p>
        <p class="protocal-title-bold">3. 您知悉并理解，<span class="emphasis">喵币属于在线交付的数字化商品，喵币的购买费用系数字化商品价格，而不具有预付款性质或者定金、储值等性质，喵币购买成功后不可转让或逆向兑换。</span></p>
        <p class="protocal-title-bold">4. 您不得以营利等非个人使用目的获取/使用喵币，或通过赠与、出借、转让、销售、抵押、许可他人使用等方式获取/处置喵币。</p>
        <p class="protocal-title-bold">5. <span class="emphasis">喵币和人民币的兑换比例为100：1。喵币在任何情况下都不能逆向兑换成人民币或其他法定货币，请您根据自己的实际需求购买相应数量的喵币。</span></p>

        <p class="protocal-title-bold">二、 喵币服务</p>
        <p>1. UPUPOO将为您提供与购买、兑换和/或管理喵币有关的网络增值服务，包括但不限于提供喵币的购买以及兑换服务、账户管理页面、交易明细查询服务等（以下简称“喵币服务”）。喵币服务的具体内容请以相关页面的信息为准。</p>
        <p>2. 您同意以UPUPOO留存的交易记录作为喵币购买和/或兑换的唯一有效依据，如您对UPUPOO留存的交易记录有异议，您应立即向UPUPOO提出，UPUPOO经核对后发现确有错误的，将予以改正。</p>
        <p>3. 如您的UPUPOO账号或喵币账户存在异常情形，您可以联系客服挂失或冻结您的喵币账户。此时，UPUPOO需要您提供UPUPOO账号的注册信息和购买喵币的支付信息以完成身份核验。<span class="protocal-title-bold">您理解并确认，由于技术局限，UPUPOO无法保证身份核验的准确性和真实性。</span></p>

        <p class="protocal-title-bold">三、 喵币使用规则</p>
        <p>1. 您应通过UPUPOO官方接入、授权的支付渠道，依约支付相应费用后购买喵币。</p>
        <p>2. 您应在购买喵币时仔细确认账号、支付账户、购买数量、购买费用等交易信息准确无误，<span class="protocal-title-bold">如因您自身原因（包括但不限于账号错误、支付账户错误、操作不当、账号/支付账户存在异常情况等）导致的任何损失应由您自行承担。</span></p>
        <p class="protocal-title-bold">3. 您知悉并同意，如因UPUPOO原因造成您购买喵币失实并经UPUPOO查证属实的，UPUPOO将根据查证情形采取如下措施：</p>
        <p>（1） 购买获得的喵币数量小于您实际购买数量的，UPUPOO予以补其差额；</p>
        <p>（2） 购买获得的喵币数量大于您实际购买数量的，UPUPOO有权追回差额；</p>

        <p class="protocal-title-bold">四、 免责事由</p>
        <p class="protocal-title-bold">1. UPUPOO将尽最大努力为您提供与喵币服务相关支持，但由于技术局限，UPUPOO无法保证网络服务一定能满足您的要求（包括但不限于网络服务的及时性、安全性、准确性）。</p>
        <p class="protocal-title-bold">2. 因下述不可抗力及不可归责于UPUPOO的原因而产生的损失，UPUPOO不承担任何责任，但将尽力减少因此而给您造成的损失和影响：</p>
        <p>（1） 因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素导致购买系统或服务不能正常运作；</p>
        <p class="protocal-title-bold">（2） 鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制导致的暂时关闭、病毒侵袭等影响互联网正常运行之情形；</p>
        <p>（3） 由于政府命令、法律法规的变更、司法机关及行政机关的命令、裁定等原因而导致的系统服务中断、终止或延迟；</p>
        <p>（4）如因系统维护或升级的需要而需暂停喵币服务时；</p>
        <p>（5） 其他不应归责于UPUPOO的原因导致的损失。</p>
        <p class="protocal-title-bold">3. 因您自身的原因而产生的任何损失，由您自行负责，UPUPOO不承担责任，包括但不限于：</p>
        <p>（1） 您未妥善保管您的UPUPOO账号信息（包括但不限于您告知他人账号密码或与他人共享账号等情形）导致的损失；</p>
        <p>（2） 您（包括未成年人用户）向UPUPOO提供错误、不完整、不实信息等，造成不能使用或无法正常使用喵币服务；</p>
        <p>（3） 您的UPUPOO账号失效、丢失、被封停；</p>
        <p>（4） 其他因您自身原因导致的损失。</p>
        <p class="protocal-title-bold">4. UPUPOO对于非经UPUPOO指定途径/渠道获得的喵币服务不承担任何义务和/或法律责任。</p>

        <p class="protocal-title-bold">五、 协议变更、中止及终止</p>
        <p class="protocal-title-bold">1. UPUPOO有权根据法律法规、监管政策、行业规范以及UPUPOO经营策略的变化调整，单方变更、中止、终止本协议内容，并在相关页面进行公布。</p>
        <p class="protocal-title-bold">2. 前述内容一经在相关的页面上公布即有效代替原来的协议内容，同时UPUPOO将以适当的方式（包括但不限于弹窗、邮件、站内信、系统通知、平台通知、公告等）提醒您更新后的内容，以便您及时了解本协议的最新版本。</p>
        <p class="protocal-title-bold">3. 您理解并同意，如存在以下情形，UPUPOO有权单方中止或终止本协议，或单方决定中止或终止向您提供全部或部分喵币服务，上述权利的行使无期限限制：</p>
        <p>（1） 您利用非UPUPO购买渠道或其他第三方的软件/网站/规则漏洞，通过正当或非正当手段获得喵币和/或喵币服务；</p>
        <p>（2） 您通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式更改喵币数量、喵币购买费用等信息；</p>
        <p>（3） 根据法律法规、监管政策的规定或有权机关的要求；</p>
        <p>（4） 不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制导致的暂时关闭、病毒侵袭等影响互联网正常运行之情形）；</p>
        <p>（5） 为维护账号与系统安全等紧急情况之需要的；</p>
        <p>（6） 其他可能导致中止或终止的情形。</p>
        <p class="protocal-title-bold">4. 如发生前述中止或终止的，UPUPOO将根据相关法律法规，视情况对您账号内尚未使用的喵币进行合理处理。</p>
        <p class="protocal-title-bold">5. 如因您违反本协议导致前述中止或终止的，UPUPOO有权依据您行为的性质和严重程度决定对您或您的账号采取下述一种或多种处理方式：</p>
        <p>（1） 视情况对您的账号采取永久封禁、限制功能等措施；</p>
        <p>（2） 对您账号的喵币服务进行临时冻结，冻结期限由UPUPOO视情况决定；</p>
        <p>（3） 要求您以法定货币等价返还已经消费的喵币；</p>
        <p>（4） 若您的行为已涉及违法犯罪，UPUPOO有权移交行政或司法机关处理。</p>
        <p class="protocal-title-bold">6. 除法律法规另有规定或UPUPOO另有说明外，UPUPOO无须向您和/或第三人承担任何责任。</p>

        <p class="protocal-title-bold">六、 未成年人保护</p>
        <p>1. UPUPOO非常注重未成年人的保护。<span class="protocal-title-bold">若您为未成年人，应在监护人监护、指导下阅读本协议，并在取得监护人的同意后使用喵币服务。</span></p>
        <p>2. 监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使用喵币服务，应当对未成年人使用喵币服务进行正确引导、监督。<span class="protocal-title-bold">未成年人使用喵币服务，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。</span></p>

        <p class="protocal-title-bold">七、 其它</p>
        <p>1. 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律。本协议项下因与现行法律冲突而导致部分无效，不影响其他部分的效力。</p>
        <p class="protocal-title-bold">2. 如就本协议内容或执行发生任何争议，应尽量友好协商解决；协商不成时，则争议双方均一致同意将争议提交UPUPOO所在地的仲裁委员会按照其仲裁规则进行仲裁，仲裁裁决为一裁终局，对争议双方均有法律约束力。</p>
        <p>3. 本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。</p>
        <p style="text-align: right">UPUPOO</p>
        <p style="text-align: right">武汉小咪网络科技有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {
  },
  data() {
    return {
      isMobileMain: false,
    };
  },
  created() {
    this.isMobileMain = this.isMobile();
    console.log(this.isMobileMain);
  },
  mounted() {
    if (this.isMobileMain) {
      this.init();
      this.getRem();
    }
  },
  computed: {},
  methods: {
    // 判断是不是移动端
    isMobile() {
      const UA = window.navigator.userAgent.toLowerCase();
      let ios = UA.match(/iphone/i) == "iphone" || UA.match(/ipad/i) == "ipad" ? !0 : !1,
        adr = UA.match(/android/i) == "android" ? !0 : !1;
      return ios || adr;
    },
    getRem() {
      (function (doc, win) {
        let docEl = doc.documentElement,
          resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
          recalc = function () {
            let clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (clientWidth >= 750) {
              docEl.style.fontSize = "100px";
            } else {
              docEl.style.fontSize = 100 * (clientWidth / 750) + "px";
            }
          };

        recalc();
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener("DOMContentLoaded", recalc, false);
      })(document, window);
    },
    init() {
      let app = document.querySelector("#app");
      app.style.minWidth = "100vw";
      app.style.width = "100vw";
      app.style.minHeight = "100vh";
      app.style.height = "auto";
      if (app.querySelectorAll(".__header")[0]) app.querySelectorAll(".__header")[0].style.display = "none";
      if (app.querySelectorAll(".header_block")[0]) app.querySelectorAll(".header_block")[0].style.display = "none";
      if (app.querySelectorAll(".footer")[0]) app.querySelectorAll(".footer")[0].style.display = "none";
      if (app.querySelectorAll(".box")[0]) app.querySelectorAll(".box")[0].style.minWidth = "100vw";
    },
  },
};
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    color: #000;
    font-size: 16px;

    span {
      display: inline;
    }
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }

  .emphasis {
    color: red;
    text-decoration: underline;
  }
}
.filePage {
  &.isMobileMain {
    span {
      display: inline-block;
    }

    .content-title {
      font-size: 0.32rem !important;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: rgba(29, 29, 29, 1);
      line-height: 0.48rem !important;
      margin-top: 0.32rem;
    }

    .text-first {
      text-indent: 0.4rem;
      margin-top: 0.32rem;
    }
    em {
      font-style: normal;
      font-weight: bold;
      color: rgba(29, 29, 29, 1);
    }
    .span-title {
      font-weight: bold;
      color: rgba(29, 29, 29, 1);
      display: inline !important;
    }

    .p-title {
      font-weight: bold;
      color: rgba(29, 29, 29, 1);
    }

    .no-indext {
      text-indent: 0px !important;
    }

    .p-behind {
      display: inline !important;
    }

    .text-p {
      text-indent: 0.4rem;
      margin-top: 0.32rem;
    }

    .protocol {
      width: 100%;
      height: 100%;
      background: #fff;
      overflow: hidden;
    }

    .protocol-content {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      // border-top: 0.01rem solid #d8d8d8;
      font-size: 0.32rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #5f5f5f;
      padding: 0 0.3rem;
      line-height: 0.48rem;
      text-align: left;

      padding-bottom: 1rem;
      overflow-y: auto;
    }
    .protocol-head {
      background-color: #fff;
      box-shadow: 0 0.02rem 0.04rem 0 rgba(85, 85, 85, 0.1);
    }

    .protocol-head-container {
      width: 100%;
      height: 0.6rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }

    .protocol-head-container a {
      display: flex;
      align-items: center;
    }

    .protocol-head-container .logo {
      width: 0.42rem;
      height: 0.42rem;
      line-height: 0.42rem;
      margin-left: 0.1rem;
      text-align: center;
      font-size: 0;
      display: inline-block;
    }

    .protocol-head-container img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }

    .protocol-head-container .text {
      font-size: 0.28rem;
      color: #4a4a4a;
      margin-left: 0.1rem;
      line-height: 0.42rem;
    }

    .protocol-layout-body {
      width: 100%;
      margin: 0.22rem auto 0;
      color: #000;
      font-size: 0.32rem;
    }

    .protocol-layout-body h3 {
      padding: 0.04rem 0 0.24rem;
      font-size: 0.32rem;
      border-bottom: 0.01rem solid #e7e7eb;
      margin-bottom: 0.24rem;
    }

    .protocol-layout-body p {
      margin-bottom: 0.2rem;
      font-size: 0.28rem;
      line-height: 0.42rem;
    }

    .protocal-title-bold {
      font-weight: bold;
    }
    .emphasis {
      color: red;
      text-decoration: underline;
    }
  }
}
</style>
